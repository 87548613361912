/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState, useRef } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import objectUtils, { sortBy } from '../../../../utils/object-utils'
import { Bar } from 'react-chartjs-2'
import dataClient from '../../../../services/dataClient'
import moment from 'moment'
import { useHistory } from 'react-router'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const labels = [
  { code: '01', name: 'Jan' },
  { code: '02', name: 'Feb' },
  { code: '03', name: 'Mar' },
  { code: '04', name: 'Apr' },
  { code: '05', name: 'May' },
  { code: '06', name: 'Jun' },
  { code: '07', name: 'Jul' },
  { code: '08', name: 'Aug' },
  { code: '09', name: 'Sep' },
  { code: '10', name: 'Oct' },
  { code: '11', name: 'Nov' },
  { code: '12', name: 'Dec' }
]

const colorsActive = [
  'rgba(4, 128, 237, 1.0)', // royal blue
  'rgba(225, 65, 51, 0.9)', // orange red
  'rgba(243, 182, 3, 1.0)', // gold
  'rgba(49, 162, 80, 1.0)', // green
  'rgba(120, 163, 244, 1.0)', // light blue
  'rgba(65, 183, 192, 1.0)', // teal
  'rgba(246, 106, 0, 1.0)', // orange
  'rgba(171, 16, 33, 0.8)', // maroon
  'rgba(122, 57, 195, 0.8)', // light purple
  'rgba(55, 222, 165, 0.85)', // aqua green
  'rgba(242, 86, 167, 0.85)', // pink
  'rgba(247, 239, 84, 1.0)', // yellow
  'rgba(53, 70, 221, 0.8)', // purple-blue
  'rgba(252, 157, 5, 0.85)', // orange
  'rgba(186, 67, 164, 0.85)', // eggplant
  'rgba(111, 224, 98, 1.0)' // lime green
]

export default function StackedBarChart (props) {
  const groups = props.groups || []
  const organization = props.organization || {}

  const [loading, setLoading] = useState(true)
  const [isChartDataReady, setIsChartDataReady] = useState(false)
  const [isResidential, setIsResidential] = useState(false)
  const [historicalTotals, setHistoricalTotals] = useState(null)
  const [renderableData, setRenderableData] = useState(null)
  const [chartOptions, setChartOptions] = useState(null)
  const history = useHistory()

  // Define DOM refs
  const chartContainerRef = useRef()

  const groupsSorted = objectUtils.orderBy(groups, item => item.stats_total.total_in_lbs, ['desc'])
  const showMaxGroups = groups => groups.slice(0, props.maxGroupsToShow)
  const groupsToShow = showMaxGroups(groupsSorted)

  // Validates if there is sufficient data to render the chart
  const validateChartDataReady = (historical) => {
    let historicalHasData = false

    if (historical.org_type && historical.org_type === 'city') {
      // validate residential data
      historicalHasData = historical.communities && Object.keys(historical.communities).length > 0
    } else {
      // validate commercial data
      historicalHasData = historical.locations && Object.keys(historical.locations).length > 0
    }

    return historicalHasData
  }

  const createChartData = () => {
    const dataset = []

    // Residential
    if (isResidential) {
      let i = 0
      for (const community in historicalTotals.communities) {
        if (!historicalTotals.communities[community].totals) break
        const totalsData = Object.values(historicalTotals.communities[community].totals).reverse()
        const roundedData = totalsData.map(it => Math.round(it))
        dataset.push({
          label: historicalTotals.communities[community].name,
          data: roundedData,
          backgroundColor: colorsActive[i]
        })
        i++
      }
    // Commercial
    } else {
      let i = 0
      for (const [alias, entry] of Object.entries(historicalTotals.locations)) {
        if (!entry.totals) break
        const locationData = groupsToShow.find(loc => loc.url_alias === alias) // access location data
        if (!locationData) break
        const totalsData = Object.values(entry.totals)
        totalsData.reverse() // reverse order
        const roundedData = totalsData.map(it => Math.round(it))
        dataset.push({
          label: locationData.name,
          data: roundedData,
          backgroundColor: colorsActive[i],
          alias
        })
        i++
      }
    }

    const sortedData = sortBy(dataset, 'label')
    const currentMonth = moment().format('MM')
    const currentMonthIdx = labels.findIndex((e) => e.code === currentMonth)
    const chartLabels = [...labels.slice(currentMonthIdx, labels.length), ...labels.slice(0, currentMonthIdx)].map((month) => month.name)

    setRenderableData({ labels: chartLabels, datasets: sortedData })
  }

  // Load data on mount
  useEffect(() => {
    async function loadData () {
      setLoading(true)

      // Determine customer type and obtain root org for totals request
      const isRes = (organization.org_type === 'community')
      const orgAlias = (isRes) ? organization.parent_org_alias : organization.url_alias
      setIsResidential(isRes) // set customer type

      // Fetch historical totals for the groups to show
      const historical = await dataClient.fetchHistoricalTotals(orgAlias, groupsToShow.map(group => group.url_alias))

      // Set data for chart display (if ready)
      if (validateChartDataReady(historical)) {
        setHistoricalTotals(historical)
        setIsChartDataReady(true) // signify chart is able to display
      }
      setLoading(false)
    }
    loadData()
  }, [])

  useEffect(() => {
    if (historicalTotals) {
      createChartData()
    }
  }, [historicalTotals])

  // Create customized chart options
  useEffect(() => {
    if (renderableData) {
      // Define chart options
      const options = {
        plugins: {
          title: {
            display: false
          },
          legend: isResidential
            ? {
                labels: {
                  boxWidth: 13
                }
              }
            : {
                onClick: (event, lgdItem) => {
                  const alias = renderableData.datasets.find((e) => e.label === lgdItem.text).alias
                  history.push(alias)
                  history.go()
                },
                labels: {
                  boxWidth: 13
                }
              }
        },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          x: {
            stacked: true
          },
          y: {
            stacked: true
          }
        }
      }

      setChartOptions(options)
    }
  }, [renderableData])

  // Draw chart
  return (
    <div ref={chartContainerRef} className='chart-container'>
      {loading && 'Loading...'}
      <div className='chart-wrapper'>
        {/* the chart */}
        {renderableData && renderableData.datasets && chartOptions &&
          <Bar options={chartOptions} data={renderableData} style={{ height: '320px' }} />}

        {/* placeholder message when data not ready */}
        {!loading && !isChartDataReady &&
          <div className='chart-data-not-ready'>
            (Historical data is being processed)
          </div>}
      </div>
    </div>
  )
}
